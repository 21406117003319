<template>
  <div style="background-color: #fff">
    <div class="recharge-step van-hairline--bottom">
      <span style="font-size: 1.5rem">Step 1： {{ $t("请转账至") }}</span>
      <div style="padding: 10px">
        {{ $t("银行") }}： {{ bankInfo.bankName }}
        <div @click="copyBankAccount()">
          {{ $t("账号") }}： {{ bankInfo.acctNumber }}
          <span>{{ $t("[复制]") }}</span>
        </div>
        {{ $t("户名") }}： {{ bankInfo.acctName }}
      </div>
    </div>
    <div class="recharge-step van-hairline--bottom">
      <span style="font-size: 1.5rem">Step 2： {{ $t("上传网银截图") }}</span>

      <div style="text-align: center; padding: 20px 0px">
        <van-uploader
          v-model="fileList"
          :after-read="afterRead"
          multiple
          :max-count="1"
        />
      </div>
      <div class="saveButton">
        <van-button
          @click="doSubmit"
          color="#EE502F"
          :loading="submitLoading"
          :disabled="submitLoading"
        >
          {{ $t("确定") }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Uploader, Field, Button, Toast } from "vant";
import { getBankInfo } from "@/service/api";
import { uploadSlip, draftOrderUpdate } from "./service";
import { getConfig } from "@/utils/token";

export default {
  components: {
    [Uploader.name]: Uploader,
    [Field.name]: Field,
    [Button.name]: Button,
  },
  data() {
    return {
      submitLoading: false,
      bankInfo: {},
      fileList: [],
      file: null,
      imageUrl: null,
      queryParams: this.$route.query,
    };
  },
  mounted() {
    // this.getBankInfo();
    const config = getConfig();
    this.bankInfo = config.orderAccount;
  },
  methods: {
    afterRead(file) {
      this.file = file;
    },
    async draftOrderUpdate() {
      const payload = {
        id: this.queryParams.orderId,
        input: {
          customAttributes: [
            {
              key: "slip_url",
              value: this.imageUrl,
            },
          ],
        },
      };
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const { data } = await draftOrderUpdate(payload);
      Toast.clear();
      const { draftOrder, userErrors } = data.draftOrderUpdate;

      if (draftOrder) {
        this.$router.replace({
          name: "orderSuccess",
        });
      }

      if (userErrors && userErrors.length > 0) {
        const { message } = userErrors[0];
        Toast.fail(message);
      }
    },
    async uploadScreenshot() {
      let formData = new FormData();
      const { orderId, name } = this.queryParams;
      formData.append("draftOrderId", orderId);
      formData.append("draftOrderName", name);
      formData.append("file", this.file.file);

      this.submitLoading = true;

      const res = await uploadSlip(formData);
      if (res.success) {
        const { imageUrl } = res.data;
        this.imageUrl = imageUrl;
        this.draftOrderUpdate();
      } else Toast.fail(res.message);
      this.submitLoading = false;
    },
    copyBankAccount() {
      let oInput = document.createElement("input");
      oInput.value = this.bankInfo.account;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast.success(this.$t("复制成功"));
      oInput.remove();
    },
    async getBankInfo() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await getBankInfo();

      if (res.success) {
        Toast.clear();
        this.bankInfo = res.data;
      } else Toast.fail(res.message);
    },
    doSubmit() {
      if (!this.file) {
        Toast.fail(this.$t("上传网银截图"));
        return;
      }

      this.uploadScreenshot();
    },
  },
};
</script>
<style lang="less" scoped>
.recharge-step {
  padding: 15px;
}

.saveButton {
  margin: 15px 15px 0 15px;
  padding-bottom: 15px;
  padding-top: 30px;
  text-align: center;
}

.van-button--normal {
  border-radius: 5px;
  padding: 0px 40px;
}

:deep(.van-uploader__preview-image) {
  width: 180px !important;
  height: 100px !important;
}

:deep(.van-uploader__upload) {
  width: 180px;
  height: 100px;
}

:deep(.van-field__label) {
  margin-right: 0px;
}

.van-cell {
  padding: 10px 0px;
}
</style>